import React, {useState} from "react"
import JSONdata from "../../../content/ourWorkData.json";
import ClientCard from "../clientCard/clientCard";
import GithubLogoSVG from "../../svg/githubLogo.svg";
import ForgerockSVG from "../../svg/clients/forgerockSVG.svg";
import {graphql, useStaticQuery} from "gatsby";
import * as styles from "./ourWorkCardsSection.module.scss"
import {GatsbyImage} from "gatsby-plugin-image";
import {Button} from "react-bootstrap";
import DownArrowSVG from "../../svg/downArrow.svg"
import {Simulate} from "react-dom/test-utils";

export default function OurWorkCardsSection() {

    const images = useStaticQuery(graphql`{
        image0: file(relativePath: {eq: "our-work/image-1.png"}) {
          childImageSharp {
            gatsbyImageData(quality: 70, layout: CONSTRAINED)
          }
        },
        image1: file(relativePath: {eq: "our-work/image-2.png"}) {
          childImageSharp {
            gatsbyImageData(quality: 70, layout: CONSTRAINED)
          }
        },    
        image2: file(relativePath: {eq: "our-work/image-3.png"}) {
          childImageSharp {
            gatsbyImageData(quality: 70, layout: CONSTRAINED)
          }
        },
        image3: file(relativePath: {eq: "our-work/image-4.png"}) {
          childImageSharp {
            gatsbyImageData(quality: 70, layout: CONSTRAINED)
          }
        }       
  }`);

    const initCardCount = 4;
    const cardsToLoad = 4;

    const [loadCount, setLoadCount] = useState<number>(initCardCount);

    let cards: any = JSONdata.clientTilesList.slice(0, loadCount).map((data: any, idx: number) => {
        return <CardWithImage imageOnLeft={idx % 2 !== 0} key={idx}
                              image={eval(`images.image${idx % 4}.childImageSharp.gatsbyImageData`)}
                              card={<ClientCard logo={data.slug.includes('github') ? <GithubLogoSVG/> : <ForgerockSVG />} name={data.name} header={data.header}
                                                text={data.text}
                                                statistics={data.statistics} background={data.color} slug={data.slug} />}/>
    });

    return <div>
        <div className={styles.cardSectionWrapper}>
            {cards}
        </div>
        {JSONdata.clientTilesList.length > loadCount && <Button onClick={() => setLoadCount(loadCount+cardsToLoad)} className={styles.button}>
            <div className={styles.buttonText}>
                Load More <DownArrowSVG/>
            </div>
        </Button>}
    </div>
}

function CardWithImage(props: CardWithImageProps) {
    return <div className={styles.cardWrapper}>
        <div className={styles.desktop}>
            {props.imageOnLeft ? <>
                    <GatsbyImage alt={"person"} image={props.image} class={styles.image}/>
                    {props.card}
                </>
                :
                <>
                    {props.card} <GatsbyImage alt={"person"} image={props.image} class={styles.image}/>
                </>}
        </div>
        <div className={styles.mobile}>
            {props.card}
            <GatsbyImage alt={"person"} image={props.image} class={styles.image}/>
        </div>

    </div>
}

interface CardWithImageProps {
    image: any,
    card: any,
    imageOnLeft: boolean
}
