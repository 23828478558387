import React, {CSSProperties, ReactElement} from "react"
import * as styles from "./clientCard.module.scss"
import {Col, Row} from "react-bootstrap";
import RedRightArrowSVG from "../../svg/redRightArrow.svg"
import { Link } from "gatsby";

export default function ClientCard(props: ClientCardProps) {

    const style = {
        background: props.background,
    }

    return <Row className={styles.cardWrapper}
                style={style}>
        <Col className={styles.infoWrapper}
             xs={{offset: 1, span: 10, order: 2}} lg={{offset: 1, span: 6, order: 1}}>
            <div className={styles.name}>
                {props.name}
            </div>
            <div className={styles.header}>
                {props.header}
            </div>
            <div className={styles.text}>
                {props.text}
            </div>
            <div className={styles.statisticsWrapper}>
                {props.statistics && props.statistics.slice(0, 3).map((data, idx) => <div className={styles.statisticsItem}
                                                                              key={idx}>
                    <div className={styles.statisticsValue}>{data.value}</div>
                    <div className={styles.statisticsName}>{data.name}</div>
                </div>)}
            </div>
            <Link to={`/ourwork/${props.slug}`}>
                <RedRightArrowSVG/>
            </Link>
        </Col>
        <Col className={styles.logoWrapper} xs={{offset: 1, span: 10, order: 1}}
             lg={{offset: 1, span: 3, order: 2}}>
            {props.logo}
        </Col>
    </Row>
}

interface ClientCardProps {
    logo?: ReactElement,
    name?: string,
    header?: string,
    text?: string,
    statistics?: Statistic[],
    background?: string,
    slug: string
}

export interface Statistic {
    value: string;
    name: string;
    iconName: string;
}
