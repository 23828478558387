// extracted by mini-css-extract-plugin
export var cardWrapper = "clientCard-module--cardWrapper--20358";
export var header = "clientCard-module--header--0517c";
export var infoWrapper = "clientCard-module--infoWrapper--344f9";
export var logoWrapper = "clientCard-module--logoWrapper--8bc97";
export var name = "clientCard-module--name--938e8";
export var preview = "clientCard-module--preview--01f0b";
export var statisticsItem = "clientCard-module--statisticsItem--1e89e";
export var statisticsName = "clientCard-module--statisticsName--4ecf6";
export var statisticsValue = "clientCard-module--statisticsValue--03873";
export var statisticsWrapper = "clientCard-module--statisticsWrapper--bbc59";
export var text = "clientCard-module--text--5ba0c";